export default function Link({ text, onClick }) {
  return (
    <div
      className="text-gray-400 cursor-pointer hover:underline"
      onClick={() => onClick()}
    >
      {text}
    </div>
  );
}
