import Divider from "../Divider";
import QuestionAnswer from "../common/QuestionAnswer";

const questions = ["q1", "q2", "q3", "q4", "q5", "q6", "q7", "q8", "q9", "q10"];
const answers = ["a1", "a2", "a3", "a4", "a5", "a6", "a7", "a8", "a9", "a10"];

export default function ProductQuestions({ product }) {
  return (
    <div className="flex flex-col mb-8 mr-8 ml-8 p-8 justify-center gap-4">
      <div className="font-bold text-2xl flex items-center justify-center mb-4">
        FAQ
      </div>

      {questions.map((q, i) => {
        if (product[q]) {
          return (
            <QuestionAnswer
              question={product[q]}
              answer={product[answers[i]]}
            />
          );
        }
      })}
    </div>
  );
}
