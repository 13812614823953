import { renderHtmlFromRaw, isEmptyHTML } from "../../utils/htmlUtils";

export default function ProductBuisnessModel({ product }) {
  return (
    <div className="flex flex-col mb-8 mr-8 ml-8 p-8 justify-center items-center">
      <div className="max-w-7xl flex flex-col gap-4">
        {!isEmptyHTML(product.buisnessModel) && (
          <div className="shadow-box">
            <div className="font-bold text-2xl flex items-center justify-center mb-4">
              Geschäftsmodell
            </div>
            {renderHtmlFromRaw(product.buisnessModel)}
          </div>
        )}
        {!isEmptyHTML(product.marketDescription) && (
          <div className="shadow-box">
            <div className="font-bold text-2xl flex items-center justify-center mb-4">
              Markt
            </div>
            {renderHtmlFromRaw(product.marketDescription)}
          </div>
        )}
      </div>
    </div>
  );
}
