export default function Divider({ text }) {
  return (
    <div className="flex justify-center">
      <div
        className="text-center flex justify-center items-center pb-2 
      pt-2 max-w-[70rem]"
      >
        <div className="border-t border-gray-400 w-20 md:w-80"></div>
        <div className="mx-4 font-bold text-2xl lg:w-[25rem] ">{text}</div>
        <div className="border-t border-gray-400 w-20 md:w-80"></div>
      </div>
    </div>
  );
}
