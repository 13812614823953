export let BASE_URL = ``;

// check production enviroment to use the corresponding URL
if (
  !process.env.REACT_APP_ENVIRONMENT ||
  process.env.REACT_APP_ENVIRONMENT === `local`
) {
  BASE_URL = "http://localhost:8000";
} else if (process.env.REACT_APP_ENVIRONMENT === `development`) {
  BASE_URL = "https://dev-smartefunding-backend-jgq9d.ondigitalocean.app";
} else {
  // production
  BASE_URL = "https://prod-smartefunding-backend-6shr9.ondigitalocean.app";
}
