import { renderHtmlFromRaw, isEmptyHTML } from "../../utils/htmlUtils";
import Divider from "../Divider";
import ProductFiles from "./ProductFiles";

export default function ProductParticipationModel({ product }) {
  return (
    <div className="flex flex-col mb-8 mr-8 ml-8 p-8 justify-center items-center">
      <div className="max-w-7xl flex flex-col gap-4">
        {!isEmptyHTML(product.participationOffer) && (
          <div className="shadow-box">
            <div className="font-bold text-2xl flex items-center justify-center mb-4">
              Beteiligungsangebot
            </div>
            {renderHtmlFromRaw(product.participationOffer)}
          </div>
        )}
        {!isEmptyHTML(product.useOfCapital) && (
          <div className="shadow-box">
            <div className="font-bold text-2xl flex items-center justify-center mb-4">
              Kapitalverwendung
            </div>
            {renderHtmlFromRaw(product.useOfCapital)}
          </div>
        )}
        {!isEmptyHTML(product.zeichnungsProcedure) && (
          <div className="shadow-box">
            <div className="font-bold text-2xl flex items-center justify-center mb-4">
              Zeichnungsablauf
            </div>
            {renderHtmlFromRaw(product.zeichnungsProcedure)}
          </div>
        )}
        <div className="shadow-box">
          <div className="font-bold text-2xl flex items-center justify-center mb-4">
            Dokumente
          </div>
          <ProductFiles product={product} />
        </div>
      </div>
    </div>
  );
}
